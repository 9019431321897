<template>
  <div
    :class="`
      field
      ${requiredField? 'required' : ''}
    `"
  >
    <label :for="`applicant_${id}`">{{ label }}</label>
    <Field
      v-slot="{ errors, field, meta }"
      ref="myField"
      :name="`applicant[${id}]`"
      :rules="rules"
      :validateOnModelUpdate="isEager"
    >
      <span :class="`${meta.validated && !meta.valid && rules !== '' ? 'error' : ''}`">
        <input
          v-bind="field"
          :id="`applicant_${id}`"
          v-model.trim="dateInputValue"
          :data-pattern="pattern"
          :maxlength="maxlength"
          :placeholder="placeholder"
          type="text"
          @input="$emit('update:modelValue', $event.target.value)"
          @keypress="applyDateMask($event)"
          @keypress.once="handleChange(meta)"
          @change.once="handleChange(meta)"
        >
      </span>

      <div
        v-if="meta.validated && !meta.valid && rules !== ''"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </Field>
  </div>
</template>

<script>
/*
    Props:
  - id: date input's id - prepended with 'applicant_'
  - label: date input's label text
  - maxlength: date input's maxlength attribute (dates are either MM/YYYY or DD/MM/YYYY)
  - pattern: converted into a data-pattern attribute, used by the input mask
  - placeholder: text input's placeholder text
  - requiredField: boolean expressing whether the label should indicate that this field is required
  - rules: validation rules to be passed to the Field component

  Listeners:
  - input - emits 'update:modelValue' so that model in parent component (e.g. AdditionalExams) is updated
  - keypress.once & change.once - to simulate eager validation
  - keypress - ensures that only numbers and slashes can be entered into the field
*/
import { Field } from 'vee-validate';
import applyDateMask from '../../helpers/date-input-mask';

export default {
  name: 'DateInput',
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxlength: {
      type: String,
      required: true,
    },
    pattern: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      dateInputValue: '',
      isEager: false,
    };
  },
  methods: {
    applyDateMask,
    handleChange(meta) {
      // VeeValidate v4 removed eager validation mode
      // https://github.com/logaretm/vee-validate/issues/3088
      // Toggling validateOnModelUpdate only after initial change event has fired means that
      // the field is not validated until the user has interacted with it once then moved on.
      //
      // This also ensures that eager mode is activated on first keypress if form has been submitted
      // without the field being touched. And covers the edgecase of ensuring that validation fires
      // immediately when interacting with a field, having  my userscript to populate the form.
      if (meta.validated) this.isEager = true;
    },
  },
};
</script>
