import { configure, defineRule } from 'vee-validate';
import {
  between, email, min, numeric, required,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

import {
  isAfter,
  isBetween,
  isChronological,
  isCorrectFormat,
  isDecimal,
  isValidDate,
} from './helper';

const COURSE_START_DATE = 'October 1 2023 00:00';

export default function initValidation() {
  configure({
    // Generates an English message locale generator
    generateMessage: localize('en', {
      messages: {
        between: 'This field must be between 0:{min} and 1:{max}',
        email: 'This is not a valid email address',
        min: 'This field must contain at least 0:{min} characters',
        numeric: 'This field must be a whole integer',
        required: 'This field is required',
      },
    }),
  });

  defineRule('between', between);
  defineRule('email', email);
  defineRule('min', min);
  defineRule('numeric', numeric);
  defineRule('required', required);

  // Custom
  defineRule('additionalRequired', (value) => {
    if (!value) {
      return 'Or this field is required';
    }

    return true;
  });

  defineRule('after', (value, [startDate, name]) => {
    // Do nothing if the start date is empty
    if (!startDate) return true;

    if (!isChronological(value, startDate)) {
      return `This must be later than ${name} start date`;
    }

    return true;
  });

  defineRule('date_between', (value, [dateFrom, dateTo]) => {
    if (!isBetween(value, dateFrom, dateTo)) {
      return `This field must be between ${dateFrom} and ${dateTo}`;
    }

    return true;
  });

  defineRule('date_format', (value, [format]) => {
    if (!isCorrectFormat(value, format)) {
      return `This field must be in the format ${format}`;
    }

    return true;
  });

  defineRule('decimal', (value, [places]) => {
    if (!isDecimal(value, places)) {
      return `This field must be numeric and may contain ${places} decimal places`;
    }

    return true;
  });

  defineRule('greaterThanZero', (value) => {
    if (value <= 0) {
      return 'This field must be greater than zero';
    }

    return true;
  });

  defineRule('maximumLength', (value, [number]) => {
    // For some reason, when starting a rules prop with a rule which accepts a value
    // e.g. rules="maximumLength:500", the rule always fires on initial render.
    // Checking for undefined solves this problem for now, but this is a code smell that needs addressing.
    // Currently happening in: awards.vue
    if (typeof value === 'undefined') return true;

    if (value.length > Number(number)) {
      return `This field may not be greater than ${number} characters`;
    }

    return true;
  });

  defineRule('isBeforeCourseStartDate', (value) => {
    if (isAfter(value, COURSE_START_DATE)) {
      return 'This is after the course has started';
    }

    return true;
  });

  defineRule('validDate', (value) => {
    if (!isValidDate(value)) {
      return 'This is not a valid date';
    }

    return true;
  });

  defineRule('validGre', (value) => {
    if (!value.match(/^[0-9,.]+$/)) {
      return 'This field can only contain numbers, decimal points or commas';
    }

    return true;
  });

  defineRule('validPhoneNumber', (value) => {
    if (!value.match(/^[0-9()+\s-]*$/)) {
      return 'This field may only contain digits or + - ( )';
    }

    return true;
  });
}
