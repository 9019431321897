<template>
  <div>
    <div class="first form-section">
      <h4>Personal Information</h4>
    </div>

    <div class="three fields">
      <TextInput
        id="first_name"
        label="First Name"
        placeholder="First Name"
        required-field
        rules="required"
      />
      <TextInput
        id="middle_name"
        label="Middle Name"
        placeholder="Middle Name"
      />
      <TextInput
        id="last_name"
        label="Last Name"
        placeholder="Last Name"
        required-field
        rules="required"
      />
    </div>

    <div class="two fields">
      <Select
        id="gender"
        label="Gender"
        :opts="['Male', 'Female', 'Rather not say']"
        prompt="Gender"
        required-field
        rules="required"
      />
      <DateInput
        id="date_of_birth"
        label="Date of Birth"
        maxlength="10"
        pattern="DD/MM/YYYY"
        placeholder="DD/MM/YYYY"
        required-field
        rules="required|date_format:DD/MM/YYYY|validDate|date_between:01/01/1900,01/01/2010"
      />
    </div>

    <div class="two fields">
      <CountrySelect
        id="country_of_birth"
        label="Country of Birth"
        placeholder="Country of Birth"
        required-field
        rules="required"
      />
      <TextInput
        id="marital_status"
        label="Marital Status"
        placeholder="Marital Status"
        required-field
        rules="required"
      />
    </div>

    <div class="two fields">
      <TextInput
        id="children"
        label="Children"
        maxlength="1"
        placeholder="Number of Children"
        rules="numeric"
      />
    </div>
  </div>
</template>

<script>
import CountrySelect from '../generic/country-select.vue';
import DateInput from '../generic/date-input.vue';
import Select from '../generic/select.vue';
import TextInput from '../generic/text-input.vue';

export default {
  name: 'PersonalInformation',
  components: {
    CountrySelect,
    DateInput,
    Select,
    TextInput,
  },
};
</script>
