<template>
  <div
    :class="`
      field
      ${classes}
      ${requiredField? 'required' : ''}
    `"
  >
    <label :for="`applicant_${id}`">{{ label }}</label>
    <Field
      v-slot="{ errors, field, meta }"
      :name="`applicant[${id}]`"
      :rules="rules"
      :validateOnModelUpdate="isEager"
    >
      <span :class="`${meta.validated && !meta.valid && rules !== '' ? 'error' : ''}`">
        <input
          v-bind="field"
          :id="`applicant_${id}`"
          v-model.trim="textInputvalue"
          :disabled="disabled"
          :maxlength="maxlength"
          :placeholder="placeholder"
          type="text"
          @input="$emit('update:modelValue', $event.target.value)"
          @keypress.once="handleChange(meta)"
          @change.once="handleChange(meta)"
          @blur.once="$emit('blur')"
        >
      </span>
      <div
        v-if="meta.validated && !meta.valid && rules !== ''"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </Field>
  </div>
</template>

<script>
/*
  Props:
  - classes: additional class names to pass to semantic UI (e.g. 3 fields)
  - disabled: text input's disabled state
  - id: text input's id - prepended with 'applicant_'
  - label: text input's label text
  - maxlength: text input's maxlength attribute (e.g. applicant_children)
  - placeholder: text input's placeholder text
  - requiredField: boolean expressing whether the label should indicate that this field is required
  - rules: validation rules to be passed to the Field component

  Listeners:
  - input - emits 'update:modelValue' so that model in parent component (e.g. AdditionalExams) is updated
  - keypress.once & change.once - to simulate eager validation
  - blur - currently used in AcademicHistory & AdditionalExams to fire validation when assosciated input is blured
*/
import { Field } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    Field,
  },
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxlength: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue', 'blur'],
  data() {
    return {
      textInputvalue: '',
      isEager: false,
    };
  },
  methods: {
    handleChange(meta) {
      // VeeValidate v4 removed eager validation mode
      // https://github.com/logaretm/vee-validate/issues/3088
      // Toggling validateOnModelUpdate only after initial change event has fired means that
      // the field is not validated until the user has interacted with it once then moved on.
      //
      // This also ensures that eager mode is activated on first keypress if form has been submitted
      // without the field being touched.
      //
      if (meta.validated) this.isEager = true;
    },
  },
};
</script>
