<template>
  <Form
    id="new_applicant"
    v-slot="{ validate, validateField, setFieldValue }"
    class="ui form segment"
    action="/applicants"
    accept-charset="UTF-8"
    method="post"
  >
    <!--
      ValidationObservers have been removed from VeeValidate v4, so we need to pull the validateField
      method off the form and pass it down to:
      - AcademicHistory component (to validate existing fields before adding a new section)
      - Additional Exams (to synchronize validation of an assosciated group of GRE fields)
      This is hacky. Better to use the Composition API
      See: https://github.com/logaretm/vee-validate/discussions/3828

      setFieldValue is being passed to:
      - PrincipalInvestigators, so that it can update the Field component's value, whenever the dynamic dropdown
        changes (otherwise validation doesn't work)
      - TermsAndConditions, as otherwise it won't register registering programatic changes from my Tampermonkey
    -->
    <PersonalInformation />
    <CorrespondenceAddress />
    <AcademicHistory :validate="validateField" />
    <Awards />
    <AdditionalExams :validate="validateField" />
    <PrincipalInvestigators :set="setFieldValue" />
    <TextAreas />
    <TermsAndConditions :set="setFieldValue" />

    <div :class="`ui error message ${errorClass}`">
      Unfortunately, one or more errors prevented this form from being submitted.
    </div>

    <button
      class="ui blue submit button"
      :disabled="submitButtonIsDisabled"
      @click="onSubmit(validate)"
    >
      Submit
    </button>
  </Form>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { Form } from 'vee-validate';

import CorrespondenceAddress from './main/correspondence-address.vue';
import PersonalInformation from './main/personal-information.vue';

// Async stuff
const AcademicHistory = defineAsyncComponent(() => import('./main/academic-history.vue'));
const Awards = defineAsyncComponent(() => import('./main/awards.vue'));
const AdditionalExams = defineAsyncComponent(() => import('./main/additional-exams.vue'));
const PrincipalInvestigators = defineAsyncComponent(() => import('./main/principal-investigators.vue'));
const TextAreas = defineAsyncComponent(() => import('./main/text-areas.vue'));
const TermsAndConditions = defineAsyncComponent(() => import('./main/terms-and-conditions.vue'));

export default {
  name: 'App',
  components: {
    Form,
    CorrespondenceAddress,
    PersonalInformation,
    AcademicHistory,
    Awards,
    AdditionalExams,
    PrincipalInvestigators,
    TextAreas,
    TermsAndConditions,
  },
  data() {
    return {
      errorClass: '',
      submitButtonIsDisabled: false,
    };
  },
  mounted() {
    // Append hidden input containing CSRF token to form, as otherwise Rails will
    // respond with an empty session, which means flash messages won't work.
    const authTokenInput = document.querySelector('input[name="authenticity_token"]');
    document.getElementById('new_applicant').prepend(authTokenInput);
  },
  methods: {
    async onSubmit(validateForm) {
      const res = await validateForm();
      if (res.valid) {
        this.submitButtonIsDisabled = true;
        document.forms[0].submit();
      } else {
        this.errorClass = 'visible';
      }
    },
  },
};
</script>
