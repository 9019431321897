import '../jquery-shim';

import '../../../vendor/assets/fomantic-ui/dist/components/api.min';
import '../../../vendor/assets/fomantic-ui/dist/components/dropdown.min';
import '../../../vendor/assets/fomantic-ui/dist/components/transition.min';

import { createApp } from 'vue';

import VueSweetalert2 from 'vue-sweetalert2';

// sweetalert2 installed by VueSweetalert2 (which is essentially just a wrapper)
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css';

import App from '../components/App.vue';
import initGlobal from '../helpers/dom-global';
import initValidation from '../helpers/validation-rules';

document.addEventListener('DOMContentLoaded', () => {
  initGlobal();

  const el = document.getElementById('app');

  if (el) {
    initValidation();
    createApp(App)
      .use(VueSweetalert2)
      .mount('#app');
  }
});
