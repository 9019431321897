<template>
  <div
    :class="`
      field
      ${requiredField? 'required' : ''}
    `"
  >
    <label :for="`applicant_${id}`">{{ label }}</label>
    <Field
      v-slot="{ errors, field, meta }"
      ref="myField"
      :name="`applicant[${id}]`"
      :rules="rules"
    >
      <span :class="`${meta.validated && !meta.valid ? 'error' : ''}`">
        <select
          v-bind="field"
          :id="`applicant_${id}`"
          ref="myDropdown"
          v-model="selectValue"
          class="ui fluid dropdown"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <option value="">
            {{ prompt }}
          </option>
          <option
            v-for="option in opts"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </span>

      <div
        v-if="meta.validated && !meta.valid"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </Field>
  </div>
</template>

<script>
/* global $ */

/*
  Props:
  - id: select's id - prepended with 'applicant_'
  - label: select's label text
  - opts: array containing options for select
  - prompt: select's prompt text
  - requiredField: boolean expressing whether the label should indicate that this field is required
  - rules: validation rules to be passed to the Field component

  Listeners:
  input - emits 'update:modelValue' so that model in parent component (e.g. AcademicHistory) is updated
*/
import { Field } from 'vee-validate';

export default {
  name: 'Select',
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    opts: {
      type: Array,
      required: true,
    },
    prompt: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectValue: '',
      isTouched: false,
    };
  },
  mounted() {
    $(this.$refs.myDropdown).dropdown({
      transition: 'vertical flip',
      onHide: () => { this.handleClose(); },
    });
  },
  unmounted() {
    $(this.$refs.myDropdown).dropdown('destroy');
  },
  methods: {
    handleClose() {
    // When dropdown is closed without user having selected anything, ensure that validation fires.
    // This is consistent with the way that text inputs work.
      if (this.isTouched) return;

      if (this.selectValue === '') {
        this.$refs.myField.setTouched(true);
        this.$refs.myField.handleChange();
        this.isTouched = true;
      }
    },
  },
};
</script>
