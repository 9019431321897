import { DateTime } from 'luxon';

function makeDateFromString(dateString) {
  const [dd, mm, yyyy] = dateString.split('/');
  return new Date(yyyy, mm - 1, dd);
}

export function isAfter(dateString, maxDate) {
  // eslint-disable-next-line no-param-reassign
  if (dateString.match(/^\d{2}\/\d{4}$/)) dateString = `01/${dateString}`;

  const [dd, mm, yyyy] = dateString.split('/');
  const date = new Date(yyyy, mm - 1, dd);
  return date > new Date(maxDate);
}

export function isBetween(value, dateFrom, dateTo) {
  const userEnteredDate = makeDateFromString(value);
  const startDate = makeDateFromString(dateFrom);
  const endDate = makeDateFromString(dateTo);

  return (userEnteredDate >= startDate && userEnteredDate <= endDate);
}

export function isChronological(value, startDate) {
  // Validation should only fire if start date has been filled out
  if (!startDate.match(/^\d{2}\/\d{4}$/)) return true;

  // As this will fire last, I think we can assume each field contains the correct input
  const [mm1, yyyy1] = startDate.split('/');
  const [mm2, yyyy2] = value.split('/');

  const date1 = new Date(`${yyyy1}-${mm1}-01`);
  const date2 = new Date(`${yyyy2}-${mm2}-01`);

  return date1 < date2;
}

export function isCorrectFormat(value, format) {
  // Does date string match format?
  let regex;
  if (format === 'DD/MM/YYYY') regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (format === 'MM/YYYY') regex = /^\d{2}\/\d{4}$/;

  return value.match(regex);
}

export function isDecimal(value, places) {
  // Tests that a value is numeric
  // It may optionally contain a decimal point and `places` decimal places
  // https://stackoverflow.com/a/308124/
  const regex = new RegExp(`^\\d+(\\.\\d{1,${places}})?$`);

  return regex.test(value);
}

export function isValidDate(dateString) {
  // Deal with MM/YYYY format
  // eslint-disable-next-line no-param-reassign
  if (dateString.match(/^\d{2}\/\d{4}$/)) dateString = `01/${dateString}`;

  const [dd, mm, yyyy] = dateString.split('/');

  const date = DateTime.fromObject({
    day: Number(dd),
    month: Number(mm),
    year: Number(yyyy),
  });

  return date.isValid;
}

export const stringWithLength = (chrs) => '#'.repeat(chrs);
