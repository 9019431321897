<template>
  <div
    :class="`
      field
      ${requiredField? 'required' : ''}
    `"
  >
    <label :for="`applicant_${id}`">{{ label }}</label>
    <Field
      v-slot="{ errors, field, meta }"
      ref="myField"
      :name="`applicant[${id}]`"
      :rules="rules"
    >
      <span :class="`${meta.validated && !meta.valid ? 'error' : ''}`">
        <div
          ref="countryDropdown"
          class="ui fluid search selection country dropdown"
        >
          <input
            v-model="selectValue"
            type="hidden"
            v-bind="field"
            @change="(e) => { selectValue = e.target.value; }"
          >
          <i class="dropdown icon" />
          <input
            :id="`applicant_${id}`"
            class="search"
            autocomplete="off"
            tabindex="0"
          >
          <div class="default text">
            {{ placeholder }}
          </div>
        </div>
      </span>

      <div
        v-if="meta.validated && !meta.valid"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </Field>
  </div>
</template>

<script>
/* globals $ */

/*
  Props:
  - id: select's id - prepended with 'applicant_'
  - label: select's label text
  - placeholder: select's placeholder text. As you can search in the field, no prompt necessary
  - requiredField: boolean expressing whether the label should indicate that this field is required
  - rules: validation rules to be passed to the Field component

  Listeners:
  - The @change handler ensures that the model is updated, which means validation can work
*/

import { Field } from 'vee-validate';

export default {
  name: 'CountrySelect',
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update'],
  data() {
    return {
      selectValue: '',
      isTouched: false,
    };
  },
  mounted() {
    $(this.$refs.countryDropdown).dropdown({
      apiSettings: {
        url: '/countries/index.json',
      },
      filterRemoteData: true,
      forceSelection: false,
      onHide: () => { this.handleClose(); },
    });
  },
  unmounted() {
    $(this.$refs.countryDropdown).dropdown('destroy');
  },
  methods: {
    // When dropdown is closed without user having selected anything, ensure that validation fires.
    // This is consistent with the way that text inputs work.
    handleClose() {
      if (this.isTouched) return;

      if (this.selectValue === '') {
        this.$refs.myField.setTouched(true);
        this.$refs.myField.handleChange();
        this.isTouched = true;
      }
    },
  },
};
</script>
