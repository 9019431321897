/* globals $ */
/* eslint-disable no-alert */

export default async function initGlobal() {
  // Quick nav in header
  $('.dropdown.quicknav').dropdown({ on: 'hover' });
  $('.country').dropdown({ forceSelection: false });

  // Close flash message
  $('.message.closable .close.icon').on('click', (e) => {
    e.preventDefault();
    $('.message.closable').fadeOut();
  });

  // Add confirm delete dialogue to button_to tags with a class of destroy
  // This feature was implemented in @rails/ujs which is no longer loaded by default
  const destroyForms = document.querySelectorAll('.destroy');
  destroyForms.forEach((form) => {
    form.addEventListener('submit', (e) => {
      const sure = window.confirm('Are you sure?');
      if (!sure) e.preventDefault();
    });
  });

  // Mark as unsuitable in Applicant#index
  $('#mark-unsuitable').on('submit', function handleSubmit(e) {
    e.preventDefault();

    const applicantID = this.applicant_id.value;
    const applicantCell = document.querySelector(`[data-id="${applicantID}"]`);

    if (!applicantCell) {
      window.alert(`Cannot find applicant no. ${applicantID}`);
      return;
    }

    this.submit();
  });

  // Deal with country selects
  function elementIsPresent(selector) {
    if (!document.querySelector(selector).length) {
      window.requestAnimationFrame(() => elementIsPresent(selector));
    }
  }

  if (document.querySelector('form.editing')) {
    await elementIsPresent('div.country input.search');
    const countrySelects = document.querySelectorAll('.country');
    countrySelects.forEach((select) => {
      const hiddenDiv = select.querySelector('input[type="hidden"]');
      const textDiv = select.querySelector('.text');
      if (textDiv.innerText !== hiddenDiv.value) {
        textDiv.innerText = hiddenDiv.value;
        textDiv.style.color = 'black';
      }
    });
  }

  // Zap all non JS content
  const nonJsElements = document.querySelectorAll('.no_js');
  if (nonJsElements) nonJsElements.forEach((elem) => elem.parentNode.removeChild(elem));
}
